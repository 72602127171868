import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Customers from './components/Customers/Customers';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import OrderForm from './components/OrderForm/OrderForm';
import OrderSuccess from './components/OrderSuccess/OrderSuccess';
import PresentationPdf from './components/PresentationPdf/PresentationPdf';
import Promotions from './components/Promotions/Promotions';
import Renewals from './components/Renewals/Renewals';
import Spinner from './components/Spinner/Spinner';
import { useAuth0 } from './contexts/auth0-context';
import { checkLastBuild } from './shared/utils';
import { setUser } from './store/actions/authActions';
import { setAppLoaded } from './store/actions/uiActions';

let interval = null;

function App() {
    const { isLoading, user, loginWithRedirect, getTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const appLoaded = useSelector(state => state.ui.appLoaded);

    useEffect(() => {
        if (!isLoading && !user) {
            loginWithRedirect();
        }
        else if (user) {
            window.Sentry.configureScope((scope) => {
                scope.setUser({
                    'username': user.email
                });
            });

            if (interval) {
                clearInterval(interval);
            }
            else {
                checkLastBuild();
            }

            interval = setInterval(checkLastBuild, 30000);

            const getToken = async () => {
                const token = await getTokenSilently();
                dispatch(setUser({
                    ...user,
                    token
                }));
                dispatch(setAppLoaded(true));
            };

            getToken();
        }
    });

    return (
        <Router basename="/web">
            <Header />
            <ToastContainer
                draggable={false}
                closeOnClick={false}
                newestOnTop={true}
                position={toast.POSITION.TOP_RIGHT}
            />
            <div className="hero content">
                <div className="hero-body">
                    {isLoading && (
                        <Spinner />
                    )}
                    {!isLoading && appLoaded && user &&
                    <Switch>
                        <Route path="/presentation/:product" exact>
                            <PresentationPdf />
                        </Route>
                        <Route path="/presentation" exact>
                            <Redirect to="/presentation/aida-en" />
                        </Route>
                        <Route path="/order" exact>
                            <OrderForm />
                        </Route>
                        <Route path="/order/success" exact>
                            <OrderSuccess />
                        </Route>
                        <Route path="/promotions" exact>
                            <Promotions />
                        </Route>
                        <Route path="/customers" exact>
                            <Customers />
                        </Route>
                        <Route path="/renewals" exact>
                            <Renewals />
                        </Route>
                        <Route path="/" exact>
                            <Home />
                        </Route>
                        <Route path="*">
                            <Redirect to="/" />
                        </Route>
                    </Switch>}
                </div>
            </div>
            {!isLoading && <Footer />}
        </Router>
    );
}

export default App;
