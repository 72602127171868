import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

export const AVAILABLE_CURRENCIES = {
    USD: {
        id: 'USD',
        label: 'US Dollar',
        symbol: '$',
        thousandSeparator: ',',
        decimalSeparator: '.'
    },
    GBP: {
        id: 'GBP',
        label: 'Pound sterling',
        symbol: '£',
        thousandSeparator: ',',
        decimalSeparator: '.'
    },
    EUR: {
        id: 'EUR',
        label: 'Euro',
        symbol: '€',
        thousandSeparator: '.',
        decimalSeparator: ','
    }
};

export const checkLastBuild = async () => {
    console.log('Checking latest build...');
    const { data: build } = await axios('/build.manifest');
    console.log('Got build', build);

    const handleReload = () => {
        window.location.reload();
    };

    const localBuild = process.env.REACT_APP_CI_PIPELINE_ID;
    if (localBuild < build) {
        console.log(`Build is old: server got ${build}, local is ${localBuild}`);
        setTimeout(() => {
            toast.info(<>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    A new version of the portal is available, <a href="#" onClick={handleReload}>reload page</a> to get it.
                </>,
                {
                    autoClose: false,
                    closeButton: false,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    toastId: 'newVersionAvailable'
                });
        }, 30_000);
    }
    else {
        console.log(`Build is up to date: server got ${build}, local is ${localBuild}`);
    }
};

export const getPortalInfo = () => {
    let documentTitle;
    let footerCompanyComponent;
    let headerLogo;
    let favicon;
    let email;

    if (window.location.href.indexOf('xhecom') > 0) {
        documentTitle = 'Xhecom Partners Portal';
        footerCompanyComponent = <a href="https://www.xhecom.com" target="_blank" rel="noopener noreferrer">Xhecom</a>;
        headerLogo = require('../assets/images/xhecom-logo.gif');
        favicon = require('../assets/favicon/xhecom.ico');
        email = 'info@xhecom.com';
    }
    else {
        documentTitle = 'AIDA Partners Portal';
        footerCompanyComponent =
            <a href="https://www.tclab.it" target="_blank" rel="noopener noreferrer">Technology & Cognition LAB</a>;
        headerLogo = require('../assets/images/tclab-logo.png');
        favicon = require('../assets/favicon/tclab.ico');
        email = 'support@aidacloud.com';
    }

    return {
        documentTitle,
        footerCompanyComponent,
        headerLogo,
        favicon,
        email
    };
};

export const getUserDetails = (user) => {
    const { level = 'BRONZE' } = user[ 'https://partners.xhecom.com/app_metadata' ];
    return {
        level
    };
};

export const isAdmin = user => {
    const { role = 'USER' } = user[ 'https://partners.xhecom.com/app_metadata' ];
    return role === 'ADMIN';
};

export const formatDate = date => {
    if (date instanceof Date && !isNaN(date)) {
        return new Date(date * 1000).toLocaleDateString('it-it', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }
    else {
        return 'NA';
    }
};

export const getOrderTypes = () => {
    return [
        { value: 'new', displayValue: 'First order, new customer' },
        { value: 'renewalUpgradeStandard', displayValue: 'Existing customer, standard upgrade to higher plan' },
        {
            value: 'renewalUpgradeDiscount',
            displayValue: 'Existing customer, upgrade to higher plan with page discount'
        },
        { value: 'renewalAnticipation', displayValue: 'Existing customer, anticipation of contract renewal' },
        { value: 'oneTimePurchase', displayValue: 'Existing customer, one-time purchase of pages' }
    ];
};

export const getCountries = () => {
    return [
        { value: 'AF', displayValue: 'Afghanistan' },
        { value: 'AX', displayValue: 'Åland Islands' },
        { value: 'AL', displayValue: 'Albania' },
        { value: 'DZ', displayValue: 'Algeria' },
        { value: 'AS', displayValue: 'American Samoa' },
        { value: 'AD', displayValue: 'Andorra' },
        { value: 'AO', displayValue: 'Angola' },
        { value: 'AI', displayValue: 'Anguilla' },
        { value: 'AQ', displayValue: 'Antarctica' },
        { value: 'AG', displayValue: 'Antigua and Barbuda' },
        { value: 'AR', displayValue: 'Argentina' },
        { value: 'AM', displayValue: 'Armenia' },
        { value: 'AW', displayValue: 'Aruba' },
        { value: 'AU', displayValue: 'Australia' },
        { value: 'AT', displayValue: 'Austria' },
        { value: 'AZ', displayValue: 'Azerbaijan' },
        { value: 'BS', displayValue: 'Bahamas' },
        { value: 'BH', displayValue: 'Bahrain' },
        { value: 'BD', displayValue: 'Bangladesh' },
        { value: 'BB', displayValue: 'Barbados' },
        { value: 'BY', displayValue: 'Belarus' },
        { value: 'BE', displayValue: 'Belgium' },
        { value: 'BZ', displayValue: 'Belize' },
        { value: 'BJ', displayValue: 'Benin' },
        { value: 'BM', displayValue: 'Bermuda' },
        { value: 'BT', displayValue: 'Bhutan' },
        { value: 'BO', displayValue: 'Bolivia, Plurinational State of' },
        { value: 'BQ', displayValue: 'Bonaire, Sint Eustatius and Saba' },
        { value: 'BA', displayValue: 'Bosnia and Herzegovina' },
        { value: 'BW', displayValue: 'Botswana' },
        { value: 'BV', displayValue: 'Bouvet Island' },
        { value: 'BR', displayValue: 'Brazil' },
        { value: 'IO', displayValue: 'British Indian Ocean Territory' },
        { value: 'BN', displayValue: 'Brunei Darussalam' },
        { value: 'BG', displayValue: 'Bulgaria' },
        { value: 'BF', displayValue: 'Burkina Faso' },
        { value: 'BI', displayValue: 'Burundi' },
        { value: 'KH', displayValue: 'Cambodia' },
        { value: 'CM', displayValue: 'Cameroon' },
        { value: 'CA', displayValue: 'Canada' },
        { value: 'CV', displayValue: 'Cape Verde' },
        { value: 'KY', displayValue: 'Cayman Islands' },
        { value: 'CF', displayValue: 'Central African Republic' },
        { value: 'TD', displayValue: 'Chad' },
        { value: 'CL', displayValue: 'Chile' },
        { value: 'CN', displayValue: 'China' },
        { value: 'CX', displayValue: 'Christmas Island' },
        { value: 'CC', displayValue: 'Cocos (Keeling) Islands' },
        { value: 'CO', displayValue: 'Colombia' },
        { value: 'KM', displayValue: 'Comoros' },
        { value: 'CG', displayValue: 'Congo' },
        { value: 'CD', displayValue: 'Congo, the Democratic Republic of the' },
        { value: 'CK', displayValue: 'Cook Islands' },
        { value: 'CR', displayValue: 'Costa Rica' },
        { value: 'CI', displayValue: 'Côte d\'Ivoire' },
        { value: 'HR', displayValue: 'Croatia' },
        { value: 'CU', displayValue: 'Cuba' },
        { value: 'CW', displayValue: 'Curaçao' },
        { value: 'CY', displayValue: 'Cyprus' },
        { value: 'CZ', displayValue: 'Czech Republic' },
        { value: 'DK', displayValue: 'Denmark' },
        { value: 'DJ', displayValue: 'Djibouti' },
        { value: 'DM', displayValue: 'Dominica' },
        { value: 'DO', displayValue: 'Dominican Republic' },
        { value: 'EC', displayValue: 'Ecuador' },
        { value: 'EG', displayValue: 'Egypt' },
        { value: 'SV', displayValue: 'El Salvador' },
        { value: 'GQ', displayValue: 'Equatorial Guinea' },
        { value: 'ER', displayValue: 'Eritrea' },
        { value: 'EE', displayValue: 'Estonia' },
        { value: 'ET', displayValue: 'Ethiopia' },
        { value: 'FK', displayValue: 'Falkland Islands (Malvinas)' },
        { value: 'FO', displayValue: 'Faroe Islands' },
        { value: 'FJ', displayValue: 'Fiji' },
        { value: 'FI', displayValue: 'Finland' },
        { value: 'FR', displayValue: 'France' },
        { value: 'GF', displayValue: 'French Guiana' },
        { value: 'PF', displayValue: 'French Polynesia' },
        { value: 'TF', displayValue: 'French Southern Territories' },
        { value: 'GA', displayValue: 'Gabon' },
        { value: 'GM', displayValue: 'Gambia' },
        { value: 'GE', displayValue: 'Georgia' },
        { value: 'DE', displayValue: 'Germany' },
        { value: 'GH', displayValue: 'Ghana' },
        { value: 'GI', displayValue: 'Gibraltar' },
        { value: 'GR', displayValue: 'Greece' },
        { value: 'GL', displayValue: 'Greenland' },
        { value: 'GD', displayValue: 'Grenada' },
        { value: 'GP', displayValue: 'Guadeloupe' },
        { value: 'GU', displayValue: 'Guam' },
        { value: 'GT', displayValue: 'Guatemala' },
        { value: 'GG', displayValue: 'Guernsey' },
        { value: 'GN', displayValue: 'Guinea' },
        { value: 'GW', displayValue: 'Guinea-Bissau' },
        { value: 'GY', displayValue: 'Guyana' },
        { value: 'HT', displayValue: 'Haiti' },
        { value: 'HM', displayValue: 'Heard Island and McDonald Islands' },
        { value: 'VA', displayValue: 'Holy See (Vatican City State)' },
        { value: 'HN', displayValue: 'Honduras' },
        { value: 'HK', displayValue: 'Hong Kong' },
        { value: 'HU', displayValue: 'Hungary' },
        { value: 'IS', displayValue: 'Iceland' },
        { value: 'IN', displayValue: 'India' },
        { value: 'ID', displayValue: 'Indonesia' },
        { value: 'IR', displayValue: 'Iran, Islamic Republic of' },
        { value: 'IQ', displayValue: 'Iraq' },
        { value: 'IE', displayValue: 'Ireland' },
        { value: 'IM', displayValue: 'Isle of Man' },
        { value: 'IL', displayValue: 'Israel' },
        { value: 'IT', displayValue: 'Italy' },
        { value: 'JM', displayValue: 'Jamaica' },
        { value: 'JP', displayValue: 'Japan' },
        { value: 'JE', displayValue: 'Jersey' },
        { value: 'JO', displayValue: 'Jordan' },
        { value: 'KZ', displayValue: 'Kazakhstan' },
        { value: 'KE', displayValue: 'Kenya' },
        { value: 'KI', displayValue: 'Kiribati' },
        { value: 'KP', displayValue: 'Korea, Democratic People\'s Republic of' },
        { value: 'KR', displayValue: 'Korea, Republic of' },
        { value: 'KW', displayValue: 'Kuwait' },
        { value: 'KG', displayValue: 'Kyrgyzstan' },
        { value: 'LA', displayValue: 'Lao People\'s Democratic Republic' },
        { value: 'LV', displayValue: 'Latvia' },
        { value: 'LB', displayValue: 'Lebanon' },
        { value: 'LS', displayValue: 'Lesotho' },
        { value: 'LR', displayValue: 'Liberia' },
        { value: 'LY', displayValue: 'Libya' },
        { value: 'LI', displayValue: 'Liechtenstein' },
        { value: 'LT', displayValue: 'Lithuania' },
        { value: 'LU', displayValue: 'Luxembourg' },
        { value: 'MO', displayValue: 'Macao' },
        { value: 'MK', displayValue: 'Macedonia, the former Yugoslav Republic of' },
        { value: 'MG', displayValue: 'Madagascar' },
        { value: 'MW', displayValue: 'Malawi' },
        { value: 'MY', displayValue: 'Malaysia' },
        { value: 'MV', displayValue: 'Maldives' },
        { value: 'ML', displayValue: 'Mali' },
        { value: 'MT', displayValue: 'Malta' },
        { value: 'MH', displayValue: 'Marshall Islands' },
        { value: 'MQ', displayValue: 'Martinique' },
        { value: 'MR', displayValue: 'Mauritania' },
        { value: 'MU', displayValue: 'Mauritius' },
        { value: 'YT', displayValue: 'Mayotte' },
        { value: 'MX', displayValue: 'Mexico' },
        { value: 'FM', displayValue: 'Micronesia, Federated States of' },
        { value: 'MD', displayValue: 'Moldova, Republic of' },
        { value: 'MC', displayValue: 'Monaco' },
        { value: 'MN', displayValue: 'Mongolia' },
        { value: 'ME', displayValue: 'Montenegro' },
        { value: 'MS', displayValue: 'Montserrat' },
        { value: 'MA', displayValue: 'Morocco' },
        { value: 'MZ', displayValue: 'Mozambique' },
        { value: 'MM', displayValue: 'Myanmar' },
        { value: 'NA', displayValue: 'Namibia' },
        { value: 'NR', displayValue: 'Nauru' },
        { value: 'NP', displayValue: 'Nepal' },
        { value: 'NL', displayValue: 'Netherlands' },
        { value: 'NC', displayValue: 'New Caledonia' },
        { value: 'NZ', displayValue: 'New Zealand' },
        { value: 'NI', displayValue: 'Nicaragua' },
        { value: 'NE', displayValue: 'Niger' },
        { value: 'NG', displayValue: 'Nigeria' },
        { value: 'NU', displayValue: 'Niue' },
        { value: 'NF', displayValue: 'Norfolk Island' },
        { value: 'MP', displayValue: 'Northern Mariana Islands' },
        { value: 'NO', displayValue: 'Norway' },
        { value: 'OM', displayValue: 'Oman' },
        { value: 'PK', displayValue: 'Pakistan' },
        { value: 'PW', displayValue: 'Palau' },
        { value: 'PS', displayValue: 'Palestinian Territory, Occupied' },
        { value: 'PA', displayValue: 'Panama' },
        { value: 'PG', displayValue: 'Papua New Guinea' },
        { value: 'PY', displayValue: 'Paraguay' },
        { value: 'PE', displayValue: 'Peru' },
        { value: 'PH', displayValue: 'Philippines' },
        { value: 'PN', displayValue: 'Pitcairn' },
        { value: 'PL', displayValue: 'Poland' },
        { value: 'PT', displayValue: 'Portugal' },
        { value: 'PR', displayValue: 'Puerto Rico' },
        { value: 'QA', displayValue: 'Qatar' },
        { value: 'RE', displayValue: 'Réunion' },
        { value: 'RO', displayValue: 'Romania' },
        { value: 'RU', displayValue: 'Russian Federation' },
        { value: 'RW', displayValue: 'Rwanda' },
        { value: 'BL', displayValue: 'Saint Barthélemy' },
        { value: 'SH', displayValue: 'Saint Helena, Ascension and Tristan da Cunha' },
        { value: 'KN', displayValue: 'Saint Kitts and Nevis' },
        { value: 'LC', displayValue: 'Saint Lucia' },
        { value: 'MF', displayValue: 'Saint Martin (French part)' },
        { value: 'PM', displayValue: 'Saint Pierre and Miquelon' },
        { value: 'VC', displayValue: 'Saint Vincent and the Grenadines' },
        { value: 'WS', displayValue: 'Samoa' },
        { value: 'SM', displayValue: 'San Marino' },
        { value: 'ST', displayValue: 'Sao Tome and Principe' },
        { value: 'SA', displayValue: 'Saudi Arabia' },
        { value: 'SN', displayValue: 'Senegal' },
        { value: 'RS', displayValue: 'Serbia' },
        { value: 'SC', displayValue: 'Seychelles' },
        { value: 'SL', displayValue: 'Sierra Leone' },
        { value: 'SG', displayValue: 'Singapore' },
        { value: 'SX', displayValue: 'Sint Maarten (Dutch part)' },
        { value: 'SK', displayValue: 'Slovakia' },
        { value: 'SI', displayValue: 'Slovenia' },
        { value: 'SB', displayValue: 'Solomon Islands' },
        { value: 'SO', displayValue: 'Somalia' },
        { value: 'ZA', displayValue: 'South Africa' },
        { value: 'GS', displayValue: 'South Georgia and the South Sandwich Islands' },
        { value: 'SS', displayValue: 'South Sudan' },
        { value: 'ES', displayValue: 'Spain' },
        { value: 'LK', displayValue: 'Sri Lanka' },
        { value: 'SD', displayValue: 'Sudan' },
        { value: 'SR', displayValue: 'Suriname' },
        { value: 'SJ', displayValue: 'Svalbard and Jan Mayen' },
        { value: 'SZ', displayValue: 'Swaziland' },
        { value: 'SE', displayValue: 'Sweden' },
        { value: 'CH', displayValue: 'Switzerland' },
        { value: 'SY', displayValue: 'Syrian Arab Republic' },
        { value: 'TW', displayValue: 'Taiwan, Province of China' },
        { value: 'TJ', displayValue: 'Tajikistan' },
        { value: 'TZ', displayValue: 'Tanzania, United Republic of' },
        { value: 'TH', displayValue: 'Thailand' },
        { value: 'TL', displayValue: 'Timor-Leste' },
        { value: 'TG', displayValue: 'Togo' },
        { value: 'TK', displayValue: 'Tokelau' },
        { value: 'TO', displayValue: 'Tonga' },
        { value: 'TT', displayValue: 'Trinidad and Tobago' },
        { value: 'TN', displayValue: 'Tunisia' },
        { value: 'TR', displayValue: 'Turkey' },
        { value: 'TM', displayValue: 'Turkmenistan' },
        { value: 'TC', displayValue: 'Turks and Caicos Islands' },
        { value: 'TV', displayValue: 'Tuvalu' },
        { value: 'UG', displayValue: 'Uganda' },
        { value: 'UA', displayValue: 'Ukraine' },
        { value: 'AE', displayValue: 'United Arab Emirates' },
        { value: 'GB', displayValue: 'United Kingdom' },
        { value: 'US', displayValue: 'United States' },
        { value: 'UM', displayValue: 'United States Minor Outlying Islands' },
        { value: 'UY', displayValue: 'Uruguay' },
        { value: 'UZ', displayValue: 'Uzbekistan' },
        { value: 'VU', displayValue: 'Vanuatu' },
        { value: 'VE', displayValue: 'Venezuela, Bolivarian Republic of' },
        { value: 'VN', displayValue: 'Viet Nam' },
        { value: 'VG', displayValue: 'Virgin Islands, British' },
        { value: 'VI', displayValue: 'Virgin Islands, U.S.' },
        { value: 'WF', displayValue: 'Wallis and Futuna' },
        { value: 'EH', displayValue: 'Western Sahara' },
        { value: 'YE', displayValue: 'Yemen' },
        { value: 'ZM', displayValue: 'Zambia' },
        { value: 'ZW', displayValue: 'Zimbabwe' }
    ];
};

export const getLanguages = () => {
    return [
        { value: 'IT', displayValue: 'Italiano' },
        { value: 'EN', displayValue: 'English' },
        { value: 'ES', displayValue: 'Español' },
        { value: 'DE', displayValue: 'Deutsch' },
        { value: 'FR', displayValue: 'Français' },
        { value: 'PT', displayValue: 'Português' }
    ];
};
