import clsx from 'clsx';
import React from 'react';
import { PDFObject } from 'react-pdfobject';
import { useParams } from 'react-router-dom';
import classes from './PresentationPdf.module.scss';

export default function PresentationPdf() {
    let { product } = useParams();

    let url;
    let title;
    let iframe;
    let id;
    switch (product) {
        case 'aida-it':
            title = 'Presentazione AIDA';
            iframe = <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vRYzJcc3xASI_r979O0xGufpRQ0v7ozDcN7h5oQ3XTql_iORq-MjqZJG6MI4rjHmImNWQouAKfwoQ2A/embed?start=false&loop=false&delayms=3000"
                frameBorder="0" width="1280" height="749" allowFullScreen="true" mozallowfullscreen="true"
                webkitallowfullscreen="true" title={title}></iframe>;
            id = '1VN7CSnr541O8mOOsVfYsz97ZgNMVmJvOxb8HoFTR4tc';
            break;
        case 'aida-en':
            title = 'AIDA presentation';
            iframe = <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vSpQ8VeY1IbVNkp-mgmGj9IIpq-SNIW4a4JUs_rGKWBLVd9IWMLN8m0wTnbjYmVptXlnAlQqoymeQoY/embed?start=false&loop=false&delayms=3000"
                frameBorder="0" width="1440" height="839" allowFullScreen="true" mozallowfullscreen="true"
                webkitallowfullscreen="true" title={title}></iframe>;
            id = '1GRz8zj4Umy-lBHtl0yauxeqdSkP53DhzoDkp2h2ypG0';
            break;
        case 'aida-en-partners':
            title = 'AIDA presentation (for Partners)';
            iframe = <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vQrPsJo4KRNgfVykgEvl-X8xL3vNsHhRtbD9Kw-Bcy2J8kFXZFhpOmzaVUieBgVQg880gydBuxTkY4P/embed?start=false&loop=false&delayms=3000"
                frameBorder="0" width="1440" height="839" allowFullScreen="true" mozallowfullscreen="true"
                webkitallowfullscreen="true" title={title}></iframe>;
            id = '1IS9MBWo8pOGGFvhd7Ih8NHAiSKw6v_Oh_tCzzTEFIwY';
            break;
        case 'aida-es':
            title = 'AIDA presentación';
            iframe = <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vTOy5jBe3PnwR4UGAIjE6DowHHY2lbsKWwRm5zaJeZieUjFo4RU6AXsGBT_izqQJGHEBL6DeoO5ZpcU/embed?start=false&loop=false&delayms=3000"
                frameBorder="0" width="960" height="569" allowFullScreen="true" mozallowfullscreen="true"
                webkitallowfullscreen="true" title={title}></iframe>;
            id = '1S602nW-26vNcRiFwn8rwF4rSQ28DBOo-okmLmlxNgQc';
            break;
        case 'aida-ds-bundle-it':
            title = 'AIDA/DocuShare bundle (ITA)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-ds-bundle-it.pdf';
            break;
        case 'aida-ds-bundle-en':
            title = 'AIDA/DocuShare bundle (ENG)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-ds-bundle-en.pdf';
            break;
        case 'aida-legal-it':
            title = 'AIDA/DocuShare per studio legale';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-legal-it.pdf';
            break;
        case 'aida-legal-en':
            title = 'AIDA/DocuShare for legal firms';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-legal-en.pdf';
            break;
        case 'aida-hr-it':
            title = 'AIDA/DocuShare per la gestione delle risorse umane';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-hr-it.pdf';
            break;
        case 'aida-hr-en':
            title = 'AIDA/DocuShare for Human Resources management';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-hr-en.pdf';
            break;
        case 'aida-xerox-delivery-services-it':
            title = 'AIDA/Xerox Delivery Services';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-xerox-delivery-services-it.pdf';
            break;
        case 'aida-xerox-delivery-services-en':
            title = 'AIDA/Xerox Delivery Services';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-xerox-delivery-services-en.pdf';
            break;
        case 'aida-security-en':
            title = 'AIDA Security and certifications';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-security-en.pdf';
            break;
        case 'aida-security-it':
            title = 'AIDA Sicurezza e certificazioni';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-security-it.pdf';
            break;
        case 'aida-brochure-en':
            title = 'AIDA brochure (EN)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-brochure-en.pdf';
            break;
        default:
            url = null;
    }

    return (url || iframe) ? <div className={clsx(classes.PresentationPdf, url && classes.Pdf)}>
        <h1>{title}</h1>
        {url && <PDFObject url={url} />}
        {iframe && <div>
            {iframe}
            {id && <div className={classes.Link}><a href={`https://docs.google.com/presentation/d/${id}/export/pdf`}
                                                    target="_blank" rel="noopener noreferrer">Download the presentation
                                                                                              as PDF</a></div>}
        </div>}
    </div> : <p>Nothing here...</p>;
};
